import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import profilePicture from './tristan-ruecker-fullstack-developer-java-react.300x300.jpg';
import './IntroductionComponent.css';

const IntroductionComponent = () => (
  <section>
    <div className="display-inline">
      <Container>
        <Row>
          <Col lg={4}>
            <div className="display-column center h-100">
              <img
                className="introduction-picture"
                src={profilePicture}
                aria-hidden="true"
                alt="Profile picture - Fullstack Developer Java React"
              />

              <h3 className="introduction-name text-center mt-2">
                Tristan Rücker
              </h3>
            </div>
          </Col>
          <Col>
            <div className="center display-column ps-4 pe-4 pt-2 h-100">
              <h1 className="heading text-center">
                Fullstack Developer - Java React
              </h1>
              <hr className="mb-1 mt-3 heading-hr" />
              <div className="mb-2 mt-2">
                <a
                  href="https://github.com/cyber655"
                  target="_blank"
                  aria-label="Visit my profile on Github."
                  rel="noreferrer"
                  className="github-link"
                >
                  ☕ Visit my Github account ⚛️
                </a>
              </div>
              <p>
                As a "fullstack software developer" I'm working mainly with the
                technologies Java/Spring/Hibernate in the backend and React in
                the frontend. I started with the implementation of backend code
                in my really early days and worked my way also into the
                frontend, which I now master just as well. Overall, I am a very
                versatile software developer with a broad knowledge in different
                technologies such as Vue.js, Angular, Kubernetes. For more see
                my skills section.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </section>
);

export default IntroductionComponent;
