import './SkillsComponent.css';

const SkillsComponent = () => (
  <section className="text-center display-column center">
    <h2>Extraction of skills:</h2>
    <ul className="skills center">
      <li>Java</li>
      <li>Spring Boot</li>
      <li>React</li>
      <li>Vue.js</li>
      <li>Angular</li>
      <li>JavaScript/TypeScript</li>
      <li>Python</li>
      <li>ES6</li>
      <li>SCSS</li>
      <li>Docker</li>
      <li>Kubernetes</li>
      <li>Many others</li>
    </ul>
  </section>
);

export default SkillsComponent;
