import styles from './HireComponent.module.css';
import linkedinShareButton from './linkedin_share_button.png';
import xingShareButton from './xing_share_button.png';

const HireComponent = () => (
  <section className="display-column center text-center">
    <h2>Hire me on these platforms:</h2>

    <div className={styles['social-links']}>
      <a
        href="https://www.xing.com/profile/Tristan_Ruecker"
        target="_blank"
        rel="noreferrer"
        aria-label="Visit my profile on XING."
        className={styles['social-link']}
      >
        <img
          src={xingShareButton}
          aria-hidden="true"
          alt="xing logo"
          width="100px"
          height="100px"
          className={styles['img-social-image']}
        />
        <span className={styles['social-link-text']}>XING</span>
      </a>

      <a
        href="https://www.linkedin.com/in/tristan-r%C3%BCcker-376168115/"
        rel="noreferrer"
        target="_blank"
        aria-label="Visit my profile on LinkedIn."
        className={styles['social-link']}
      >
        <img
          src={linkedinShareButton}
          aria-hidden="true"
          alt="linkedin logo"
          width="117.6"
          height="100px"
          className={`${styles['img-social-image']} ${styles['img-linkedin']}`}
        />
        <span className={styles['social-link-text']}>LinkedIn</span>
      </a>
    </div>
  </section>
);

export default HireComponent;
