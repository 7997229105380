import './LadingPage.css';
import IntroductionComponent from './components/introduction-section/IntroductionComponent';
import SkillsComponent from './components/skills-section/SkillsComponent';
import HireComponent from './components/hire-section/HireComponent';
import FooterComponent from './components/footer-section/FooterComponent';
import { Helmet } from 'react-helmet-async';

const LadingPage = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Tristan Rücker - Fullstack Java React developer</title>
      <link rel="canonical" href="https://ruecker.dev" />
      <html lang="en" />
    </Helmet>
    <main className="main">
      <IntroductionComponent />
      <hr />
      <SkillsComponent />
      <hr />
      <HireComponent />
      <hr />
      <FooterComponent />
    </main>
  </>
);

export default LadingPage;
