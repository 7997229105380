import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import LadingPage from './components/LadingPage/LadingPage';
import ImprintPage from './components/ImprintPage/ImprintPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LadingPage />} />
            <Route path="/imprint" element={<ImprintPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    </HelmetProvider>
  );
}

export default App;
