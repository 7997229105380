import { Link } from 'react-router-dom';
import styles from './FooterComponent.module.css';

const FooterComponent = () => (
  <footer className="center">
    <span>{new Date().getFullYear()} © - Tristan Rücker</span>
    <Link to="/imprint" className={styles['imprint-link']} rel="noreferrer">
      Imprint
    </Link>
  </footer>
);

export default FooterComponent;
